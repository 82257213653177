<template>
  <div>
    <div v-if="!loading" class="update-template-modal">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(updateTemplate)">
          <b-form-group>
            <!-- Set Title -->
            <b-input-group class="mb-2 d-flex flex-column">
              <div>
                <label>Title</label>
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ required: true }"
                  name="Template title"
                >
                  <b-form-input
                    v-model="updatedTemplate.title"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter the template title..."
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-input-group>

            <!-- Set Category -->
            <label>Category</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true }"
              name="Template category"
            >
              <b-form-select
                v-model="updatedTemplate.template_category_id"
                class="mb-2"
              >
                <b-form-select-option :value="null" disabled>
                  Select a category
                </b-form-select-option>
                <b-form-select-option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <!-- Set Enterprise -->
            <label>Enterprise</label>
            <enterprise-search-select
              class="mb-1"
              :selected="selectedEnterprise"
              @change="updateSelectedEnterprise"
            />

            <!-- Set Published -->
            <label>Status</label>
            <b-form-select v-model="updatedTemplate.is_published" class="mb-2">
              <b-form-select-option :value="true">Published</b-form-select-option>
              <b-form-select-option :value="false">Draft</b-form-select-option>
            </b-form-select>

            <b-input-group class="d-flex flex-row-reverse mt-4">
              <b-btn
                :disabled="invalid || loading"
                type="submit"
                variant="primary"
              >
                Update Template
              </b-btn>
              <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
            </b-input-group>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>

    <section v-else class="text-center m-5">
      <b-spinner variant="primary" label="Getting template" />
    </section>
  </div>
</template>

<script>
import helperService from "@/services/HelperService";
import EnterpriseService from "@/services/EnterpriseService";
import purpleDocsTemplateService from "@/services/PurpleDocsTemplateService";
import PurpleDocsCategoryService from "@/services/PurpleDocsCategoryService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "UpdateTemplateModal",
  components: {
    EnterpriseSearchSelect,
  },
  props: {
    template: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      updatedTemplate: {
        title: "",
        template_category_id: null,
        enterprise_uid: null,
        is_published: false,
      },
      categories: [],
      selectedEnterprise: null,
      loading: true,
    };
  },
  computed: {
    displayEnterprises() {
      return [
        { uid: "", display_name: "All Enterprises" },
        ...this.enterprises,
      ];
    },
  },
  async mounted() {
    await this.getEnterprise()
    await this.getCategories();
    this.updatedTemplate = { ...this.template };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateTemplate() {
      this.loading = true;
      try {
        const updated = this.updatedTemplate;
        if (updated.enterprise_uid.toLowerCase() === "all enterprises") {
          updated.enterprise_uid = "";
        }
        await purpleDocsTemplateService.updateTemplate(
          this.template.id,
          updated
        );
        helperService.showNotfySuccess(
          this.$toast,
          "The template has been updated successfully."
        );
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "An error occurred while updating the template. Please refresh and try again."
        );
      } finally {
        this.loading = false;
        this.$emit("refresh");
      }
    },
    async getEnterprise() {
      this.loading = true;
      try {
        if (this.$props.template.enterprise_uid.toLowerCase() === "all enterprises") {
          this.selectedEnterprise = {uid: "", display_name: "All Enterprises"};
          return;
        }

        const res = await EnterpriseService.getEnterprise(this.$props.template.enterprise_uid)
        this.selectedEnterprise = res.data;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not fetch enterprise, please refresh and try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async getCategories() {
      this.loading = true;
      try {
        const { data } = await PurpleDocsCategoryService.listCategories();
        this.categories = data.data;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not fetch categories, please refresh and try again"
        );
      } finally {
        this.loading = false;
      }
    },
    updateSelectedEnterprise(enterprise) {
      if (!enterprise) {
        this.updatedTemplate.enterprise_uid = "";
      } else {
        this.updatedTemplate.enterprise_uid = enterprise.uid;
      }
    }
  },
};
</script>

<style scoped></style>
