<template>
  <div class="purple-docs-templates">
    <b-card title="Manage Templates">
      <b-btn
        class="float-right"
        style="margin-top: -10px; margin-right: 28px"
        variant="success"
        @click="$bvModal.show('modal-create-template')"
      >
        Add New Template
      </b-btn>
      <b-card-text>
        This page shows all the Purple Docs templates.
      </b-card-text>
    </b-card>
    <b-card class="mb-0" no-body>
      <!-- Search filters -->
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Enterprise</label>
          <b-input-group-append>
            <enterprise-search-select
              :selected="selectedEnterprise"
              @change="updateSelectedEnterprise"
            />
          </b-input-group-append>
        </section>

        <section class="col-3">
          <label>Category</label>
          <b-form-select
            v-model="selectedCategory"
            class="mb-2"
            @change="handleSelectCategory()"
          >
            <b-form-select-option :value="null">
              All Categories
            </b-form-select-option>
            <b-form-select-option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </b-form-select-option>
          </b-form-select>
        </section>

        <div class="ui-spacer" />

        <section
          class="col-3 d-flex justify-content-end align-items-center"
          style="margin-right: 28px"
        >
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>

      <!-- Table -->
      <section>
        <b-table :fields="headers" :items="templates" class="data-table" hover>
          <template #cell(title)="data">
            <router-link
              :to="{
                name: 'purple-docs-template',
                params: { id: data.item.id.toString() },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </router-link>
          </template>

          <template #cell(enterprise)="data">
            {{ data.item.enterprise }}
          </template>

          <template #cell(is_published)="data">
            {{ data.item.is_published | boolToYes }}
          </template>

          <template #cell(category)="data">
            {{ data.item.template_category.name }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              class="m-0"
              no-caret
              right
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v" />
              </template>
              <b-dropdown-item-button
                class="full-nested-btn"
                @click="showTemplateModal(data.item, 'modal-delete-template')"
              >
                Delete
              </b-dropdown-item-button>
              <b-dropdown-item-button
                class="full-nested-btn"
                @click="showTemplateModal(data.item, 'modal-update-template')"
              >
                Update
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <p v-if="!templates.length" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-template" hide-footer title="Delete a template">
      <delete-modal
        v-if="selectedTemplate"
        :subtitle="selectedTemplate.title"
        title="Are you sure you wish to delete this template? This action cannot be undone."
        @close="refreshTemplates()"
        @delete="deleteTemplate(selectedTemplate.id)"
      />
    </b-modal>

    <b-modal id="modal-create-template" hide-footer title="Create a template">
      <create-template-modal @close="closeModals" @refresh="refreshTemplates" />
    </b-modal>

    <b-modal id="modal-update-template" hide-footer title="Update template">
      <update-template-modal
        :template="selectedTemplate"
        @close="closeModals"
        @refresh="refreshTemplates"
      />
    </b-modal>
  </div>
</template>

<script>
import helperService from "@/services/HelperService";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import PurpleDocsTemplateService from "@/services/PurpleDocsTemplateService";
import PurpleDocsCategoryService from "@/services/PurpleDocsCategoryService";
import CreateTemplateModal from "@/views/purpleDocs/sections/CreateTemplateModal.vue";
import UpdateTemplateModal from "@/views/purpleDocs/sections/UpdateTemplateModal.vue";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "PurpleDocsTemplates",
  components: {
    EnterpriseSearchSelect,
    CreateTemplateModal,
    DeleteModal,
    UpdateTemplateModal,
  },
  data() {
    return {
      headers: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "enterprise_uid",
          label: "Enterprise ID",
        },
        {
          key: "category",
          label: "Category",
        },
        {
          key: "is_published",
          label: "Published",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      templates: [],
      categories: [],
      selectedTemplate: null,
      selectedCategory: null,
      filters: {
        category_id: "",
        enterprise_uid: "",
      },
      selectedEnterprise: null,
    };
  },
  mounted() {
    this.getTemplates();
    this.getCategories();
  },
  methods: {
    async getTemplates() {
      try {
        const { data } = await PurpleDocsTemplateService.listTemplates(
          this.filters
        );
        const templates = data.data;
        templates.forEach(t =>
          t.enterprise_uid === ""
            ? (t.enterprise_uid = "All enterprises")
            : null
        );
        this.templates = templates;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not fetch templates, please refresh and try again"
        );
      }
    },
    async getCategories() {
      try {
        const { data } = await PurpleDocsCategoryService.listCategories();
        this.categories = data.data;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not fetch categories, please refresh and try again"
        );
      }
    },
    async deleteTemplate(id) {
      try {
        await PurpleDocsTemplateService.deleteTemplate(id);
        helperService.showNotfySuccess(
          this.$toast,
          `The template has been successfully deleted.`
        );
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "An error occurred while deleting the template. Please try again."
        );
      } finally {
        this.refreshTemplates();
      }
    },
    handleSelectCategory() {
      this.filters.category_id = this.selectedCategory;
      this.getTemplates();
    },
    closeModals() {
      this.$bvModal.hide("modal-create-template");
      this.$bvModal.hide("modal-update-template");
      this.$bvModal.hide("modal-delete-template");
    },
    showTemplateModal(template, modalName) {
      this.selectedTemplate = template;
      this.$bvModal.show(modalName);
    },
    refreshTemplates() {
      this.closeModals();
      this.getTemplates();
    },
    clearFilters() {
      this.filters = {
        category_id: null,
      };
      this.selectedTemplate = null;
      this.selectedCategory = null;

      this.enterpriseFilters = {
        page: 0,
        page_size: 15,
        search: "",
      };
      this.selectedEnterprise = null;

      this.getTemplates();
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.filters.enterprise_uid = "";
      } else {
        this.filters.enterprise_uid = this.selectedEnterprise.uid;
      }
      this.getTemplates();
    }
  },
};
</script>

<style lang="scss"></style>
